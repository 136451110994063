$pink: #e84393;
$black: #333;
$white: #fff;
$light-color: #666;
$light-bg: #eee;
$border: 0.2rem solid $black;
.header {
  height: 100%;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #ffcd00;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0 6%;
  justify-content: space-between;

  .logo {
    // font-size: 2.5rem;
    color: $black;
    font-weight: bolder;
    margin-right: auto;
  }
  .menu-icon {
    display: none;
  }
  .navbar {
    ul {
      list-style: none;
      height: 100%;
      #menu-btn {
        display: none;
      }
      li {
        cursor: pointer;
        position: relative;
        float: left;

        &:hover ul {
          display: block;
        }

        .a {
          font-size: 18px;
          // font-size: inherit;
          // color: $light-color;
          // padding: 5px;
          display: block;
          // border-bottom: 3px solid transparent;
          @media (max-width: 1024px) {
            font-size: 14px;
          }

          &:hover {
            // background: $light-bg;
            // border-bottom: 3px solid #30716e;
            color: #30716e;
            // margin-bottom: 10px;
          }
        }

        ul {
          position: absolute;
          left: 0;
          // top: 192%;
          display: none;
          // margin-top: 10%;
          li {
            .a {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  .icons div,
  .icons a {
    // font-size: 2.5rem;
    color: $black;
    cursor: pointer;
    // margin-left: 2rem;

    &:hover {
      color: $pink;
    }
  }
}

@media (max-width: 1423px) and (min-width: 922px) {
  .header {
    padding: 0 2rem;
    .navbar {
      li {
        .a {
          font-size: 14px !important;
        }
        ul {
          li a {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}
@media (min-width: 2300px) {
  .header {
    padding: 0 6rem;
    .navbar {
      li {
        .a {
          font-size: 20px !important;
          padding-left: 2rem;
        }
        ul {
          li a {
            font-size: 25px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 920px) {
  #menu-btn {
    display: inline-block;
  }

  .header {
    // padding: 0 2rem;
    .menu-icon {
      display: block;
      // position: absolute;
      top: 0;
      right: 0;
      // transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
    .navbar {
      overflow: scroll;
      height: auto;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background: $white;
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
      padding-top: 2rem;
      padding-bottom: 2rem;
      a {
        // padding: 0.25rem;
      }

      &.active {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }

      ul li {
        width: 100%;
        padding: 0.25rem;
        .a {
          font-size: 18px;
        }

        ul {
          position: relative;
          width: 100%;

          li a {
            padding-left: 0.5rem;
          }
        }
      }
    }
  }
}

.ml {
  @media (min-width: 1061px) {
    margin-left: 2rem;
  }
  @media (max-width: 1061px) and (min-width: 1023px) {
    margin-left: 1rem;
  }
  @media (min-width: 2500px) {
    margin-left: 2rem;
  }
}
.submenu {
  background-color: white;
  height: 100%;
  height: fit-content !important;
  // width: fit-content !important;
  // border-radius: 10px;
  // padding: 10px;
  padding-left: 0px;
  color: black;
  min-width: 124px;
  li {
    width: 100%;
    // padding: 10px;
    // margin: 1px;
    padding-left: 15px;
    min-height: 40px;
    &:hover {
      background-color: #00aa8c;
      color: white;
      // border-radius: 10px;
      border-bottom: 0px !important;
    }
    @media (max-width: 920px) {
      height: fit-content !important;
      &:hover {
        background-color: white;
        color: black;
      }
    }
  }
  @media (max-width: 780px) {
    padding: 0px;
  }
}
