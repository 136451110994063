@font-face {
  font-family: "TitilliumWebsemiblod";
  src: url(../assets/font/TitilliumWeb-SemiBold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "TitilliumWebreqular";
  src: url(../assets/font/TitilliumWeb-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Titilliumbold";
  src: url(../assets/font/TitilliumWeb-Bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url(../assets/font/Poppins-Light.otf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
.main {
  position: relative;
  flex: 1 1 0%;
  overflow: auto;
}
