.cont * {
  //   * [style*="color: #ffffff"] {
  //     color: black !important;
  //   }
  color: #333d3e !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 22.5px !important;
}

.desc {
  color: #698287 !important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px !important;
}
