.btn {
  border-radius: 0.0625rem;
  cursor: pointer;
  color: #30716e;
  font-family: "TitilliumWebsemiblod";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.3s ease-out;
  border: 3px solid white;
  &:not(.dense) {
    padding: 1.4375rem 2.5rem;
  }
  &.dense {
    padding: 0.625rem 1.875rem;
  }
  &.semiDense {
    padding: 1rem;
  }
  &:disabled {
    background-color: yellow !important;
    border-color: yellow !important;
    color: #30716e;
    cursor: not-allowed;
  }
  &.outlined {
    border-color: #26ffba;
    background-color: rgba(38, 255, 186, 0.1);
    &:hover:not(:disabled) {
      border-color: rgba(38, 255, 186, 0.1);
      background-color: #26ffba;
    }
  }
  &.contained {
    border-color: rgba(38, 255, 186, 0.1);
    background-color: #26ffba;
    &:hover:not(:disabled) {
      border-color: #26ffba;
      background-color: rgba(38, 255, 186, 0.1);
    }
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
}
