.video-card {
  position: relative;
  width: 80vw;
  transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1);
  height: 100%;
  aspect-ratio: 1/1.5;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.625rem;
  color: white;
  .stars {
    display: inline-flex;
    flex-wrap: nowrap;
  }
  .icon {
    position: absolute;
    right: 0;
    bottom: 0;
    width: fit-content;
    height: fit-content;
    aspect-ratio: 1;
    padding: 1rem;
  }
  .text {
    position: absolute;
    margin: 0;
    width: fit-content;
    left: 0;
    bottom: 0;
    // display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 1rem;
    text-align: left;
  }
  @media (min-width: 1024px) {
    // opacity: 0.7;
    width: 15.625rem !important;
    height: 22.0625rem;
  }
  &:hover {
    opacity: 1;
    width: 21.24645892351275rem !important;
    height: 30rem !important;
    .icon {
      position: absolute;
      animation: translate-icon 0.3s forwards;
    }
    .text {
      position: absolute;
      animation: translate-text 0.3s forwards;
    }
  }
}

@keyframes translate-icon {
  0% {
    bottom: 0;
    right: 0;
  }
  90% {
    bottom: calc(50% - (17.5px + 0.5rem));
    right: calc(50% - (17.5px + 0.5rem));
    margin: 0 auto;
    transform: scale(1.5);
  }
  100% {
    bottom: calc(50% - (17.5px + 0.5rem));
    right: calc(50% - (17.5px + 0.5rem));
    margin: 0 auto;
    transform: scale(1.5);
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 100%;
  }
}
@keyframes translate-text {
  0% {
    right: 100%;
    left: 0;
    bottom: 0;
    margin: 0 auto;
  }
  100% {
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
  }
}
@keyframes reverse-translate-icon {
  10% {
  }
  100% {
    bottom: 0;
    right: 0;
  }
}
@keyframes reverse-translate-text {
  10% {
    justify-content: start;
    align-items: start;
  }
  100% {
    right: 100%;
    left: 0;
    bottom: 0;
    margin: 0 auto;
  }
}
.video-container:hover {
  .video-card {
    &:not(:hover) {
      opacity: 0.7;
    }
    &:hover {
      opacity: 1;
    }
  }
}
