.custom_box {
  box-shadow: 1px 2px 10px 0px rgba(0, 115, 110, 0.3);
}
.topcarousel {
  margin-top: 0;
  // @media (min-width: 1550px) {
  //   margin-top: 10%;
  // }
  // @media (min-width: 2000px) {
  //   margin-top: 30%;
  // }
  // @media (min-width: 3000px) {
  //   margin-top: 40%;
  // }
}
