.carousel__arrow {
  padding: 1rem;
  border-radius: 100%;
  border: 1px solid rgb(15 118 110);
  transition-duration: 200ms;
  svg {
    transition-duration: 200ms;
    fill: #0f766e;
    width: 1rem;
    height: 1rem;
    aspect-ratio: 1;
  }
  &:disabled {
    opacity: 0.6;
  }
  &:hover:not(:disabled) {
    background-color: #0f766e;
    svg {
      fill: white;
    }
  }
}
.splide__track,
.splide__slide {
  padding: 0 !important;
}
