.scrollContainer {
  position: relative;
  width: 100%;
  height: 30px;
  background: url("./motif4.png") repeat-y;
  animation: scroll 15s linear infinite;
  overflow: hidden;
  background-size: 300%;
  @media (min-width: 768px) {
    background-size: cover;
  }
}
@keyframes scroll {
  to {
    background-position: 0px -20rem;
  }
}
