.carousel__container {
  display: flex;
  flex-wrap: nowrap;
  @media (min-width: 1024px) {
    height: 30rem !important;
  }
  width: 100%;
  overflow-x: scroll;
  margin: 0;
}
