.container {
  // z-index: 1;
  span,
  a {
    color: white;
    // opacity: 0.6;
    &:hover:not(span) {
      opacity: 1;
    }
  }
}
