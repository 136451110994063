.card {
  position: relative;
  width: 100%;
  &:not(.long) {
    aspect-ratio: 9/6;
  }
  .content {
    bottom: 0;
    width: 100%;
    margin-top: auto;
    position: absolute;
    text-align: start;
    color: white;
    & p {
      font-size: 1rem /* 16px */;
      font-weight: 700;
      line-height: 1.25;
      letter-spacing: -0.025em;
    }
    & h5 {
      text-align: start;
      width: fit-content;
      font-size: 1.5rem /* 48px */;
      font-weight: 700;
      @media (min-width: 1440px) {
        font-size: 2.5rem /* 48px */;
      }
      // line-height: 57.6px;
      // letter-spacing: -0.025em;
    }
  }
  .button {
    cursor: pointer;
    margin-left: auto;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    padding: 8px;
    border: 1px solid #26ffba;
    border-radius: 100%;
    transition: 0s;
    background: #26ffba;

    .icon {
      width: 20px;
      text-align: center;
      display: inline-flex;
      align-items: center;
      aspect-ratio: 1;
    }

    .label-hidden {
      transition: 0s;
      max-width: 0;
      opacity: 0;
      white-space: nowrap;
      color: rgb(82 82 91);
      letter-spacing: -0.025em;
      line-height: 1.25;
      font-weight: bold;
      font-size: 1rem;
    }
  }
  img {
    transition: 1s;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
  }
  &:hover,
  &:focus {
    .content {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.78) 0%,
        rgba(0, 0, 0, 0.38) 80%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    img {
      scale: 1.2;
    }
    .button {
      gap: 0.5rem;
      border-radius: 0.1875rem;

      .label-hidden {
        transition: 0.7s;
        max-width: 200px;
        opacity: 1;
      }
    }
  }
  @media (max-width: 1024px) {
    .content {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.78) 0%,
        rgba(0, 0, 0, 0.38) 80%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    .button {
      gap: 0.5rem;
      border-radius: 0.1875rem;
      .label-hidden {
        transition: 0.7s;
        max-width: 200px;
        opacity: 1;
      }
    }
  }
}
