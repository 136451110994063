.oval2 {
  width: 100%;
  height: 100%;
  background: #d4fff1;
  border-radius: 0%;
  @media (min-width: 1440px) {
    border-radius: 50% 50% 0% 0%;
    margin: 0 -20vw;
    width: 140vw;
  }
}
