@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scrollbar-color: #ffcd00 rgba(0, 0, 0, 0.1);
  scrollbar-width: thin;
  scrollbar-gutter: both-edges;
  &::-webkit-scrollbar {
    width: 5px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #ffcd00 !important;
  }

  &::-webkit-scrollbar-track {
    background: #666 !important;
  }
}
#root {
  min-height: 100vh;
}
body {
  margin: 0;
  font-family: "TitilliumWebsemiblod" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100%;
}
.font {
  font-family: "TitilliumWebreqular" !important;
}
.fontbold {
  font-family: "Titilliumbold" !important;
}

.fontfooter {
  font-family: "Poppins";
}
.textfontsubtitle * {
  font-family: "TitilliumWebsemiblod" !important;
  @media (max-width: 1100px) {
    font-size: 30px !important;
  }
}
.textfonttitlemedia * {
  font-family: "TitilliumWebsemiblod" !important;
  font-size: 36px !important;
}
.textfonttitlepress * {
  font-family: "TitilliumWebsemiblod" !important;
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 46.5px !important;
}
.textfonttitledesc * {
  font-family: "TitilliumWebsemiblod" !important;
  font-size: 30px !important;
  font-weight: 700 !important;
  line-height: 46.5px !important;
}
.textfonttitle * {
  font-size: 40px;
  font-family: "TitilliumWebsemiblod" !important;
  @media (max-width: 1100px) {
    font-size: 32px !important;
  }
}
.textfont * {
  font-family: "TitilliumWebreqular" !important;
}
.textfonttitle * {
  font-size: 40px;
  font-family: "TitilliumWebsemiblod" !important;
  @media (max-width: 1100px) {
    font-size: 32px !important;
  }
  @media (min-width: 2300px) {
    font-size: 45px;
  }
}
.textfontdesc * {
  font-family: "TitilliumWebreqular" !important;
}
/* body > #root > div {
  height: 100vh;
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul,
ol,
li {
  padding-left: 1rem;
  list-style: inherit;
}
.no-dec {
  ul,
  ol,
  li {
    padding-left: 0 !important;
    list-style: inherit;
  }
}

// a {
//   text-decoration: none;
//   font-size: 18px;
// }

.section-paddings {
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media (min-width: 1024px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media (min-width: 1440px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  @media (min-width: 1920px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
.no-btm {
  padding-bottom: 0 !important;
}
.no-tp {
  padding-top: 0 !important;
}
.no-margin-padding {
  @media (max-width: 1920px) {
    padding: 0 !important;
    margin: 0 !important;
    max-width: 100vw;
  }
}
section {
  width: 100%;
  position: relative;
}
.full-metal-alchemist {
  min-width: 100% !important;
  background-color: rosybrown !important;
}

.arrow {
  @media (min-width: 768px) {
    display: flex;
    border-radius: 100%;
    border: 2px solid rgb(15 118 110);
    color: rgb(15 118 110);
    padding: 1rem;
    margin: auto;
    justify-content: center;
    align-items: center;
  }
  display: none;
}
.arrowgallery {
  border-radius: 100%;
  padding: 0.6rem;
  border: 2px solid white;
  color: white;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    border-color: rgb(15 118 110);
    color: rgb(15 118 110);
    padding: 1rem;
  }
}

.swal2-confirm {
  background-color: #30716e !important;
}
.rdrDayToday .rdrDayNumber span:after {
  background: #30716e !important ;
}
.styles-module_item-container {
  width: 100% !important;
}
.css-tkn350-MuiTabs-root {
  background-color: #d4fff1;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #00736e !important;
  text-align: center !important;
  font-family: "TitilliumWebsemiblod" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: #00736e !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-transform: capitalize !important;
  color: var(--afracad-grey-90, #737373) !important;
  text-align: center !important;
  font-family: "TitilliumWebsemiblod" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.css-19kzrtu {
  padding-left: 0px !important;
}
.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled {
  @media (min-width: 1024px) {
    display: none;
  }
}

.rdrMonth {
  width: 100% !important;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover {
  display: none !important;
}

.slick-prev,
.slick-next {
  display: none !important;
}

.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root {
  color: #30716e !important;
}
.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: rgba(179, 207, 208, 0.4) !important;
}
.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root:hover {
  background-color: rgba(179, 207, 208, 0.2) !important;
}
.css-1v2lvtn-MuiPaginationItem-root {
  color: #30716e !important;
}
.css-4ygxeq-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  color: black !important;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: transparent !important;
}

// carousel
.carousel {
  min-width: 90%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.carousel::-webkit-scrollbar {
  width: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.carousel__container {
  white-space: nowrap;
  padding-bottom: 10px;
  display: inline-block;
}
.carousel-item {
  width: 230px;
  height: 350px;
  border-radius: 10px;
  background-color: transparent;
  overflow: hidden;
  margin-right: 20px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: 1000ms all;
  transition: 1000ms all;
  -webkit-transform-origin: center left;
  transform-origin: center left;
  position: relative;
}
.carousel-item:hover ~ .carousel-item {
  margin-top: 100px;
  -webkit-transform: translate3d(120px, 0, 0);
  transform: translate3d(120px, 0, 0);
}

.carousel__container:hover .carousel-item {
  opacity: 0.3;
  margin-bottom: 100px;
}
.carousel__container:hover .carousel-item:hover {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  opacity: 1;
}

.carousel-item__img {
  width: 100%;
  height: 350px;
  -o-object-fit: cover;
  object-fit: cover;
}

.carousel-item__details {
  opacity: 1;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.9)),
    to(rgba(0, 0, 0, 0))
  );
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  font-size: 10px;
  -webkit-transition: 450ms opacity;
  transition: 450ms opacity;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.carousel-item__details:hover {
  opacity: 1;
}

.carousel-item__details span {
  font-size: 0.9rem;
  color: #2ecc71;
}

.carousel-item__details .controls {
  padding-top: 180px;
}

.carousel-item__details .carousel-item__details--title,
.carousel-item__details--subtitle {
  color: #fff;
  margin: 5px 0;
}

.carousel.active {
  background: rgba(255, 255, 255, 0.3);
  cursor: grabbing;
  cursor: -webkit-grabbing;
  transform: scale(1);
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: transparent !important;
}
