.calendarWrap {
  display: inline-block;
  position: relative;
  text-transform: capitalize;
  width: 100%;
  & > * {
    width: 100%;
  }

  * [style*="color: rgb(61, 145, 255)"] {
    color: #30716e !important;
  }
  *::after {
    background: #30716e;
  }
}
