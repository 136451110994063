.bg {
  background: url("../assets/images/hero_images/contact_Background.svg")
    no-repeat top center;
  background-size: 250%;
  @media (min-width: 768px) {
    background-size: 100%;
  }
}
.bgCatalogue {
  background: url("../assets/images/bg-catalogue.svg") no-repeat top center;
  background-size: cover;
  background-position: center;
}
.bgOurPartner {
  background: url("../assets/images/bg-our-partiner.svg") no-repeat top center;
  background-size: 100%;
  background-position: center;
  @media (min-width: 2440px) {
    background-size: contain;
  }
}

.home-bg {
  background: url("../assets/images/bg-aboutus.svg") no-repeat top center;
  background-size: contain;
  background-position: center;
}
.bgAboutuslearning {
  background: url("../assets/images/bg-aboutus-learning.svg") no-repeat top
    center;
  background-size: contain;
  background-position: center;
}

.oval {
  width: 140vw;
  height: 100%;
  background: #d4fff1;
  border-radius: 0%;
  position: absolute;
  z-index: -1;
  @media (min-width: 1440px) {
    border-radius: 50%;
  }
}
.oval2 {
  width: 100%;
  height: 100%;
  background: #d4fff1;
  border-radius: 0%;
  @media (min-width: 1440px) {
    border-radius: 100% 100% 0% 0%;
    // margin: 0 -20vw 0 -20vw;
  }
}
.bgevent {
  @media (min-width: 1440px) {
    background: url("../assets/images/hero_images/heroevent\ \(2\).png")
      no-repeat top left;
    background-size: 100% 130%;
    background-position: left;
  }
}
.gap {
  gap: 1rem;
  @media (min-width: 1600px) {
    gap: 5rem;
  }
}
.gapevent {
  gap: 3rem;
  @media (min-width: 1600px) {
    gap: 5rem;
  }
}
// .styleList ul {
//   // list-none flex flex-col gap-2 p-0
//   display: flex;
//   flex-direction: column;
//   gap: 2;
//   padding: 20px;
//   list-style: none;
// }
.styleList li {
  // padding: 2px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: start;
}
.styleList ul li::before {
  content: url("/assets/images/itemList.svg") !important;
  height: fit-content;
  font-weight: bold;
  margin-top: 4px;
  // font-size: 30px;
}
.styleListCourse li {
  padding: 2px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}
.styleListCourse ul li::before {
  content: url("/assets/images/itemList.svg") !important;
  font-weight: bold;
  font-size: 30px;
}
// .textfont {
//   * [style*="color: #ffffff"] {
//     color: black !important;
//   }
//   p {
//     span {
//       font-size: 40px;
//       @media (max-width: 780px) {
//         font-size: 32 !important;
//       }
//     }
//   }
// }
.textfont p span {
  font-size: 40px !important;
  font-family: "TitilliumWebsemiblod" !important;
  @media (max-width: 1100px) {
    font-size: 32px !important;
  }
}
.home-bg {
  @media (min-width: 1024px) {
    width: 90%;
    top: -100px;
    left: 100px;
  }
  @media (min-width: 1120px) {
    width: 90%;
    top: -170px;
    left: 120px;
  }
  @media (min-width: 1170px) {
    width: 90%;
    top: -220px;
    left: 120px;
  }
  @media (min-width: 1220px) {
    width: 90%;
    top: -250px;
    left: 120px;
  }
  @media (min-width: 1330px) {
    width: 90%;
    top: -350px;
    left: 120px;
  }
  @media (min-width: 1440px) {
    width: 90%;
    top: -400px;
    left: 120px;
  }
  @media (min-width: 1570px) {
    width: 90%;
    top: -500px;
    left: 120px;
  }
  @media (min-width: 1680px) {
    width: 90%;
    top: -550px;
    left: 120px;
  }
  @media (min-width: 1730px) {
    width: 90%;
    top: -600px;
    left: 120px;
  }
  @media (min-width: 1800px) {
    width: 90%;
    top: -650px;
    left: 120px;
  }
  @media (min-width: 1800px) {
    width: 80%;
    top: -500px;
    left: 250px;
  }
  @media (min-width: 1900px) {
    width: 70%;
    top: -350px;
    left: 350px;
  }
  @media (min-width: 2500px) {
    width: 50%;
    top: -350px;
    left: 904px;
  }
}

.ourpartner {
  @media (min-width: 2300px) {
    margin-top: 10rem;
    h4 {
      font-size: 40px;
    }
    div {
      width: 100%;
    }
  }
}
