.svg {
  padding: 0.4rem;
  border-radius: 100%;
  border: 0.75px solid #fff;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  width: 2rem;
  height: 2rem;
  transition: 0.5s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:not(.sharedStyle):hover {
    background: white;
    color: #30716e;
  }
  &.sharedStyle:hover {
    color: white !important;
    background-color: #30716e;
  }
}
