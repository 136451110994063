.cont {
  //   * [style*="color: #ffffff"] {
  //     color: black ;
  //   }
  // display: inline-flex;
  color: rgb(16 185 129);
  &* {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
  }
}
.descr * {
  color: #737980;
  text-align: justify;
  font-family: "TitilliumWebreqular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
