.flip-btn {
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-width: 2px;
  border-color: #0f766e;
  border-radius: 0.375rem;
  padding: 0.4rem 0.7rem;
  color: #ffcd00;
  width: fit-content;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:focus {
    background-color: #0f766e;
  }
}

.card {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 0.75rem;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  text-align: center;
  &.is-big {
    @media (min-width: 786px) {
      aspect-ratio: 6;
      font-size: 1.5em;
    }
    @media (min-width: 1280px) {
      aspect-ratio: 9;
      font-size: 2em;
    }
    .overlay .first-side {
      padding: 1rem;
    }
  }
  .overlay {
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
}
.first-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  .title {
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    flex-direction: column;
  }
}

.second-side {
  position: absolute;
  text-align: center;
  background: rgba(0, 0, 0, 0.4);
  transform-origin: bottom;
  width: 100%;
  height: 100%;
  bottom: -100%;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0;
  align-items: center;
  color: white;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  .close {
    z-index: 10;
    align-self: end;
  }
  .desc {
    z-index: 10;
    overflow-y: auto;
    padding: 0 0.5rem 0.5rem;
    justify-self: center;
    margin: auto;
  }
}

.is-shown {
  .first-side {
    z-index: 0;
    top: -100%;
  }
  .second-side {
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .title,
  .flip-btn {
    transform: scale(0);
  }
}
