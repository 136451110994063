.bgSatement {
  background: url("../../assets/images/bg-statement.svg") no-repeat top center;
  background-size: contain;
  background-position: center left;
}

.bgSatement {
  background: url("../../assets/images/motif12.svg") no-repeat top center;
  background-size: 100% 200%;
  background-position: center left;
  width: 100%;
  height: 100%;
}

.enabler-color {
  color: var(--color);
}
.fonttext {
  font-family: "TitilliumWebreqular";
}
